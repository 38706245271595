import { useEffect } from 'react';

import Router from 'next/router';
import { NextPage } from 'next';

import { routes } from '~/common/constants/routes';

const RedirectNotFound: NextPage = () => {
  useEffect(() => {
    Router.push(routes.auth);
  }, []);

  return null;
};

export default RedirectNotFound;
